import { createContext, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../ChangeLanguage/i18n'; // Import the i18n instance

const LanguageContext = createContext();

export function LanguageProvider({ children }) {

  const { i18n } = useTranslation();
   
  
  // Function to change the language
  const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
  };


  return (
    <LanguageContext.Provider value={{ changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  return useContext(LanguageContext);
}