import './SelectLanguageFloat.css'
import React, { useRef, useState, useEffect } from 'react';
import ChangeLanguageImage from '../../images/change-language-img2.png';
import { useTranslation } from 'react-i18next';

function SelectLanguageFloat({ changeLanguage }) {

    const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
    const landingLanguageRef = useRef(null);
    const { i18n } = useTranslation();
  
    const handleLanguageDropdownClick = () => {
      setLanguageDropdownOpen(!languageDropdownOpen);
    };
  
    const handleLanguageDropdownOutsideClick = (event) => {
      if (landingLanguageRef.current && !landingLanguageRef.current.contains(event.target) && languageDropdownOpen) {
        setLanguageDropdownOpen(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleLanguageDropdownOutsideClick);
  
      return () => {
        document.removeEventListener('mousedown', handleLanguageDropdownOutsideClick);
      };
    }, [languageDropdownOpen]);


  return (
    <div className="change-language-container" ref={landingLanguageRef}>
        <div  onClick={handleLanguageDropdownClick} >
            <div className="change-language-logo">
              <img src={ChangeLanguageImage} alt="logo pour changer la langue"/> 
              <span>{i18n.language === 'ar' ? "عر" : "Fr"}</span>
            </div>
        </div>   
        
        {languageDropdownOpen && (
            <div className="dropdown-language-content">
                <ul>
                    <li onClick={() => changeLanguage('fr')} className={i18n.language === 'fr' ? 'selected-language' : ''}>Français</li>
                    <li onClick={() => changeLanguage('ar')} className={i18n.language === 'ar' ? 'selected-language' : ''}>عربية</li>
                </ul>
            </div>
        )}
    </div>
  )
}

export default SelectLanguageFloat
