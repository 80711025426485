import './TermsAndConditions.css'

function TermsAndConditions() {
  return (
    <div className="terms-conditions-wrapper">
      <div className="terms-conditions-content">
        <div className='terms-conditions-title'>Conditions d'utilisation de Revizo</div>

        <p>Bienvenue sur Revizo !</p>

        <p>
          Veuillez lire attentivement les conditions d'utilisation suivantes avant d'utiliser notre application.
        </p>

        <ol>
          <li>
            <p className='terms-conditions-subtitle'>1. Acceptation des Conditions</p>
            <p className='terms-conditions-text'>
              En utilisant l'application Revizo, vous acceptez pleinement et sans réserve les présentes conditions d'utilisation. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser l'application.
            </p>
          </li>

          <li>
            <p className='terms-conditions-subtitle'>2. Utilisation de l'Application</p>
            <p className='terms-conditions-text'>
              Vous vous engagez à utiliser l'application uniquement à des fins légales et conformément aux présentes conditions d'utilisation. Vous acceptez de ne pas utiliser l'application d'une manière qui pourrait endommager, désactiver, surcharger ou compromettre la sécurité de l'application.
            </p>
          </li>

          <li>
            <p className='terms-conditions-subtitle'>3. Informations Personnelles</p>
            <p className='terms-conditions-text'>
              En utilisant l'application, vous consentez à la collecte et à l'utilisation de vos informations personnelles dans le cadre de l'application.
            </p>
          </li>

          <li>
            <p className='terms-conditions-subtitle'>4. Contenu de l'Utilisateur</p>
            <p className='terms-conditions-text'>
              En soumettant du contenu à travers notre application, vous accordez à Revizo une licence mondiale pour utiliser ce contenu afin d'améliorer votre expérience utilisateur. Cela nous permet de reproduire, modifier et afficher votre contenu dans le cadre de notre service.             
            </p>
          </li>

          <li>
            <p className='terms-conditions-subtitle'>5. Abonnement</p>
            <p className='terms-conditions-text'>
              Les quiz sont réservés à la version payante de la plateforme. Lorsque vous souhaitez activer votre abonnement, veuillez vous assurer que vos informations sont correctes (dans le cas d'un paiement par livraison) et assurez-vous d'envoyer le reçu en veillant à la qualité de l'image (dans le cas d'un paiement par CCP ou BaridiMob). Une fois le paiement effectué ou la carte reçue, aucun remboursement n'est possible.
            </p>
          </li>

          <li>
            <p className='terms-conditions-subtitle'>6. Exclusion des garanties</p>
            <p className='terms-conditions-text'>
              Nous ne pouvons promettre que notre plateforme sera toujours disponible, rapide, sécurisée ou sans erreur, ni que tout son contenu sera totalement exact ou fiable. De plus, nous nous réservons le droit de suspendre ou d'arrêter la plateforme à tout moment, sans préavis.
            </p>
          </li>

          <li>
            <p className='terms-conditions-subtitle'>7. Modifications des Conditions</p>
            <p className='terms-conditions-text'>
              Revizo se réserve le droit de modifier les présentes conditions d'utilisation à tout moment. Les modifications entreront en vigueur dès leur publication sur l'application. Il est de votre responsabilité de consulter régulièrement ces conditions pour rester informé des éventuelles modifications.
            </p>
          </li>

          <li>
            <p className='terms-conditions-subtitle'>8. Résiliation</p>
            <p className='terms-conditions-text'>
              Revizo se réserve le droit de résilier votre accès à l'application en cas de non-respect des présentes conditions d'utilisation.
            </p>
          </li>

          <li>
            <p className='terms-conditions-subtitle'>9. Contact</p>
            <p className='terms-conditions-text'>
              Pour toute question concernant ces conditions d'utilisation, veuillez nous contacter à l'adresse e-mail [contact@revizo.co].
            </p>
          </li>
        </ol>

        <p>Merci de votre compréhension et de votre coopération.</p>

        <p>L'équipe Revizo</p>
      </div>
    </div>
  );
}

export default TermsAndConditions
