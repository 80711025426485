import './TermsConditions.css'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

function TermsConditions({text, classnamesize, text2}) {
    const { i18n } = useTranslation();

  return (
    <p className={` ${classnamesize} ${i18n.language === 'ar' ? 'right-to-left' : ''}`}>
        <span>{text} </span>
        <NavLink to="/termes-et-conditions" target="_blank" rel="noopener noreferrer" className='conditions-terms-link'>{text2}</NavLink>
    </p>
  )
}

export default TermsConditions
