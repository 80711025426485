// i18n.js

import i18n from 'i18next';
import { format  } from 'date-fns';
import { fr, ar } from 'date-fns/locale';
import { initReactI18next } from 'react-i18next';



i18n
  .use(initReactI18next)
  .init({
    resources: {
        fr: {
            translation: {
              /*Copy text btn */
              copiercodetext:"Copier code",
              codecopietext:"Code copié",

              /*toastify*/
              emaildereinitialisationsuccess: "Email bien envoyé!",
             
              /*wilaya delivery not available */
              textwilayanotavailable: "*** Livraison non disponible dans cette wilaya. ***",

              /*taille recu depassée erreur */
              sizeexceedederror: "La taille de l'image ne doit pas dépasser la limite de 3MB.",

              /*Popup verifier email login */
              enregistrerbtn: "Enregistrer",
              savequiztext: "La sauvegarde des réponses vous permet de reprendre là où vous vous êtes arrêtés lors de votre prochaine révision.",
              savequiztitle: "Voulez-vous sauvegarder vos réponses ?",

              /*PopupVerifyEmail */
              renvoyertext:"Renvoyer",
              fermertext :"Fermer",
              verifyemailtext1:"Le lien de confirmation a été envoyé à l'email",
              verifyemailtext2:"Afin de valider votre compte, consultez votre boîte de réception.",
              verifyemailtitle:"Confirmez votre adresse e-mail",

              /*Login */
              motdepasse: "Mot de passe",
              motdepasseoublie: "Mot de passe oublié?",
              seconnecterbtn:"Se connecter",
              ou: 'Ou',
              vousnavezpasdecompte:"Vous n'avez pas de compte ?",
              sinscrire: "S'inscrire.",
              inscrivezvous: "Inscrivez-vous",
              inscrivezvoustext: "Entrez votre email et votre mot de passe pour créer un compte.",
              vousavezdejauncompte: "Vous avez déjà un compte?",
              seconnectertext: "Se connecter.",
              motdepasseoublietitre: "Mot de passe oublié",
              motdepasseoublietext: "Vous recevrez un e-mail de réinitialisation dans votre boîte de réception.",
              reinitialiserbtn: "Réinitialiser",
              motdepassereinitialise: "Mot de passe réinitialisé?",

              /*connexion signupotp */
              connexiontitre: "Connectez-vous",
              connexionsoustitre1: "Testez vos connaissances pour préparer votre examen de baccalauréat.",
              continuerbtn: "Continuer",
              continueravecgooglebtn: "Continuer Avec Google",


              /*Connexion signupform */
              connexionformtitre: "C'est la dernière étape!",
              connexionformsoustitre: "Ajoutez vos informations pour compléter votre inscription.",
              commencerbtn:"Commencer",
              warningtrack: "Choisissez votre filière lors de l'inscription, car le contenu de l'application dépend de ce choix et ne peut être modifié par la suite.",

              //sidebar navlink
              dashboard: 'Dashboard',
              quiz: 'Quiz',
              vosquiz: 'Vos Quiz',
              creerquiz: 'Créer Quiz',
              sujets: 'Sujets',
              abonnement: 'Abonnement',
              compte: 'Compte',
              contacteznous: 'Contactez-nous',
              deconnexion: 'Déconnexion',

              /*Deconnexion */
              titredeconnexion: 'Déconnexion',
              soustitredeconnexion: 'Voulez-vous vous déconnecter ?',
              annulerbtn: 'Annuler',
              confirmerdeconnexionbtn: 'Déconnexion',

              /*Dashboard */
              dashboardtitre: 'Tableau de bord',
              dashboardsoustitre: "Évaluez vos performances et identifiez vos lacunes.",
              meilleurenote: 'Meilleure note',
              pirenote: 'Pire note',
              nombretotalde: 'Nombre total de',
              quizfait: 'Quiz fait',
              active: 'Activé',
              nonactive: 'Non activé',
              enattente: 'En attente',
              vosscores: 'Vos scores',
              dernierquizfait: 'Dernier quiz fait',
              aucunquizfait:'Aucun quiz de fait',
              commencermaintenant: 'Commencez dès maintenant',
              performanceshebdomadaires: 'Performances hebdomadaires',
              dimanche: 'dimanche',
              lundi: 'lundi',
              mardi: 'mardi',
              mercredi: 'mercredi',
              jeudi: 'jeudi',
              vendredi: 'vendredi',
              samedi: 'samedi',
              score: 'score',
              date: format(new Date(), "EEEE, d MMMM yyyy", {locale: fr}),
              dateFormat: "'Créé le' d MMMM yyyy 'à' HH:mm:ss",

              /*Vos quiz */
              vosquiztitre: 'Vos Quiz',
              vosquizsoustitre:'Découvrez les quiz à compléter ou à reprendre.',
              supprimer: 'Supprimer',
              reprendre: 'Reprendre',
              recommencer : 'Recommencer',
              page: 'Page',
              aucunquiztrouve: "Aucun quiz n'a été trouvé. Commencez par en créer un !",
              datecreationquiz: "'Créer le' d/MM/yyyy 'à' HH:mm",

              /*Le quiz*/
              question:"Question",
              qstquivantebtn: "Question suivante",
              arreterquizbtn: "Arrêter Quiz",
              suivantbtn: "Suivant",
              arreterbtn: "Arrêter",
              resultatfinal: "Résultat Final",
              resultatscoretexte1: "Vous avez eu un score de",
              resultatscoretexte2: "sur",
              resultatscoretexte3: "dans les questions auxquelles vous avez répondu.",
              quitterquizbtn: "Quitter Quiz",
              quitterbtn: "Quitter",
              refairebtn: "Refaire",
              correcte: "Correcte",
              faux: "Faux",

              /*Filtre */
              filtrermatieres: 'Matières',
              filtrertrimestres: 'Trimestres',
              filtrertypes: 'Types',
              filtrerannées: 'Années',


              /*supprimer quiz */
              supprimertitre: 'Suppression',
              supprimersoutitre: 'Voulez-vous supprimer le quiz ?',

              /*suppression Toast */
              supptoasttexte: 'Quiz supprimé!',

             /*Creer quiz */
             creerquizsoustitre:'Selectionnez vos préférences pour créer un Quiz personnalisé.',
             creerquizetape1:'Etape 1: Choisissez une matière',
             creerquizetape2:'Etape 2: Choisissez un ou plusieurs cours',
             creerquizetape3:'Etape 3: Choisissez le nombre de question',
             creerquizetape4:'Etape 4: Choisissez le type de Quiz',
             btnprecedent: 'Précédent',
             btnsuivant: 'Suivant',
             btncreerquiz: 'Créer le Quiz',
             illimite: 'illimité',
             accesinterdittitre: "Accès interdit",
             accesinterditattentetexte: "Veuillez patienter pendant l'activation de votre compte avant de pouvoir créer un quiz.",
             accesinterditattentelabel: "S'exercer gratuitement",
             accesinterditnonregletexte: "Vous devez régler votre abonnement avant de pouvoir créer un quiz. Faites des exercices gratuitement.",
             accesinterditnonreglelabel: "Payer l'abonnement",
             creele : "Crée le",
             textà: 'à',

             /*Sujets */
             sujetsoustitre: "Exercez-vous à l'aide de ces sujets et filtrez-les selon vos besoins.",
             examen: 'Examen',
             devoir: 'Devoir',
             bac: 'Bac',
             trimestre1: 'Trimestre 1',
             trimestre2: 'Trimestre 2',
             trimestre3: 'Trimestre 3',



             /*Abonnement */
             abonnementsoustitre: 'Choisissez la méthode de paiement qui vous convient.',
             offreannuelle: 'Offre Annuelle',
             abonnementunite: 'Da / personne',
             abonnementunite2: 'Da',

             abonnementpremieravantage:'Plus de 1000 sujets.',
             abonnementsecondavantage: 'Quiz illimités.',
             abonnementtoisiemeavantage: "Questions ciblées par matière et par cours.",
             abonnementquatriemeavantage: "Des statistiques pour l'évaluation." ,
             abonnementcinquiemeavantage : 'Disponible sur tous vos appareils.',
             abonnementsixiemeavantage : "Abonnement valable toute l'année scolaire",
             abonnementseptiemeavantage : "Support client réactif pour répondre à toutes vos questions.",

             abonnementregletitre: 'Votre paiement est validé!',
             abonnementreglesoutitre: "Profitez de l'application en testant vos connaissances.",
             abonnementattentetitre: 'Nous traitons vos informations!',
             abonnementattentesoutitre: "Explorez nos exercices gratuits en attendant la livraison de votre code d'activation.",
             codedejadisponible: "Vous avez déjà un code d'activation?" ,
             methodepaiementtitre : 'Méthodes de paiement :',
             premieremethodepaiement: 'Paiement par BaridiMob',
             secondemethodepaiement: 'Paiement par CCP',
             troisiememethodepaiement: 'Paiement en espèces',
             baridimobtexte: 'Effectuez le paiement, puis veuillez nous envoyer le reçu.',
             ccptexte: 'Effectuez le paiement, puis veuillez nous envoyer le reçu.',
             especetexte:"Recevez votre code d'activation à votre domicile et payer",
             especetextesuite:"Da la livraison.",
             aucuneimageselectionnee: 'Aucune image sélectionnée',
             envoyerbtn: 'Envoyer',
             adresseplaceholder:'ex: 01 Rue de SAVOIE, Hydra 16035',
             telephonelabel:'Téléphone',
             wilayalabel: 'Wilaya',
             adresselabel: 'Adresse',
             selectionnezwilaya: 'Sélectionnez votre wilaya',
             waitingconfirmationregletexte: "Profitez de l'application en testant vos connaissances.",
             waitingconfirmationregletitre: "Votre paiement est validé!",
             waitingconfirmationreglealt:"Illustration d'une confirmation de paiement",
             waitingconfirmationattentetitre: "Nous traitons vos informations!",
             waitingconfirmationattentealt:"Illustration d'une attente de confirmation de paiement",
             waitingconfirmationattenterecutexte: "Explorez nos exercices gratuits en attendant que nous traitions votre reçu.",
             waitingconfirmationattentelivraisontexte: "Explorez nos exercices gratuits en attendant la livraison de votre code d'activation",

             /*Activation code */
             activationcodetitre: 'Activez Votre Abonnement',
             activationcodesoustitre: "Si vous avez reçu votre carte d'activation, entrez le code joint pour activer votre abonnement.",
             verifiercodebtn: "Vérifier le code",
             nouscontactertexte: "Vous avez un problème avec le code ? Nous contacter",
             activationcodesoustitreregle: "Votre abonnement est activé. Il est valide jusqu'à la fin de l'année scolaire.",
             erreuractivation: "Code d'activation non valide.",

             /*Compte */
             comptesoustitre: 'Vérifiez vos informations et modifiez-les au besoin.',
             compteinformationspersonnelles: 'Informations Personnelles',
             comptevotreabonnement: 'Votre Abonnement',
             prenomlabel: 'Prénom',
             nomlabel:'Nom',
             lyceelabel:'Lycée',
             filierelabel:'Filière',
             emaillabel: 'Email',
             modifierbtn: 'Modifier',
             sauvegarderbtn: 'Sauvegarder',
             abonnementnonregletexte: "Vous n'avez pas payé d'abonnement.",
             abonnementnonregletextelien: 'Payer un abonnement',
             abonnementregletexte: "Vous avez activé un abonnement valable jusqu'à la fin de l'année scolaire.",
             abonnementattentetexte: "Paiement en cours de traitement.",
             vosinformationsbtn: 'Vos Informations',
             selectionnezfiliere: 'Sélectionnez votre filière de Bac',
             comptestatutregle:"Vous avez activé un abonnement valable jusqu'à la fin de l'année scolaire.",
             comptestatutnonregle:"Vous n'avez pas payé d'abonnement.",
             comptestatutnonreglesuite:"Payer un abonnement.",
             comptestatutattente:"Veuillez attendre la livraison de votre carte ou le traitement du reçu.",

             /*filiere */
             scientifique: "Scientifique",
             mathematiques: "Mathématiques",
             mathtechniques: "Math-techniques",
             gestioneteconomie: "Gestion et économie",
             languesetrangeres: "Langues étrangères",
             litteratureetphilosophie: "Littérature et philosophie",

             /*sujets */
              aucunsujettrouve: "Aucun sujet n'a été trouvé.",

             /*contact */
             contactsoustitre: 'Nous sommes à votre disposition pour toute sorte de question.',
             contactemailplaceholder: 'Email',
             contactobjetplaceholder: 'Objet',
             contactmessageplaceholder: 'Votre message...',
             contacttoast: 'Massage envoyé!',
             contacttoastechec: 'Massage non envoyé!',

            
             /*Matieres */
             philosophie: 'Philosophie',
             comptabilite: 'Comptabilité',
             economietmanagement: 'Economie et Management',
             mathematiques:'Mathématiques',
             physique: 'Physique',
             sciencesnaturelles: 'Sciences Naturelles',
             histoire: 'Histoire',
             geographie:'Géographie',
             geniemecanique: 'Génie Mécanique',
             genieelectrique: 'Génie Électrique',
             geniedesprocedes: 'Génie des Procédés',
             geniecivil: 'Génie Civil',
             tamazight: 'Tamazight',
             anglais: 'Anglais',
             francais: 'Français',
             espagnol: 'Espagnol',
             italien: 'Italien',
             arabe: 'Langue Arabe',
             allemand: 'Allemand',
             educationislamique: 'Education Islamique',
             droit: 'Droit',

             /*skeleton */
             matiereskeleton: 'Matière',

             /*wilaya */          
                adrar: "Adrar",
                aindefla:"Aïn Defla",
                aintemouchent: "Aïn Témouchent",
                alger: "Alger",
                annaba: "Annaba",
                batna: "Batna",
                bechar: "Béchar",
                bejaia: "Béjaïa",
                beniabbes: "Béni Abbès",
                biskra: "Biskra",
                blida: "Blida",
                bordjbadjimokhtar: "Bordj Badji Mokhtar",
                bordjbouarreridj: "Bordj Bou Arreridj",
                bouira:"Bouira",
                boumerdes: "Boumerdès",
                chlef: "Chlef",
                constantine:"Constantine",
                djanet: "Djanet",
                djelfa:"Djelfa",
                elbayadh: "El Bayadh",
                elmeniaa:"El Meniaa",
                elmghair: "El M'Ghair",
                eloued: "El Oued",
                eltarf: "El Tarf",
                ghardaia: "Ghardaïa",
                guelma: "Guelma",
                illizi:"Illizi",
                inguezzam: "In Guezzam",
                insalah: "In Salah",
                jijel: "Jijel",
                khenchela: "Khenchela",
                laghouat: "Laghouat",
                medea:"Médéa",
                mila: "Mila",
                mostaganem: "Mostaganem",
                msila: "M'Sila",
                mascara:"Mascara",
                naama:"Naâma",
                ouleddjellal:"Ouled Djellal",
                oumelbouaghi:"Oum El Bouaghi",
                ourgla:"Ourgla",
                oran:"Oran",
                relizane:"Relizane",
                saida:"Saïda",
                setif:"Sétif",
                sidibelabbes:"Sidi Bel Abbès",
                skikda:"Skikda",
                soukahras:"Souk Ahras",
                tamanrasset:"Tamanrasset",
                tebessa:"Tébessa",
                tiaret:"Tiaret",
                timimoun:"Timimoun",
                tindouf:"Tindouf",
                tipaza:"Tipaza",
                tissemsilt:"Tissemsilt",
                tiziouzou:"Tizi Ouzou",
                touggourt:"Touggourt",
                tlemcen:"Tlemcen",
        
              /*Notification*/
              aucunenotification: "Aucune notification",

              /*Not found */
              notfoundtext: "La page que vous cherchez n'existe pas! On vous suggère de retourner à l'accueil.",
              notfoundtitle: "Page Introuvable",
              notfoundlabel: "Retourner à la page Accueil",
            
              /*page vitrine */
              accueilnavlink: "Accueil",
              servicesnavlink: "Services",
              paimentnavlink: "Paiement",
              aperçunavlink: "Aperçu",
              contactnavlink: "Contact",
              connexionbtn: "Connexion",

              commencergratuitement: "Commencer gratuitement",
              
              contacteznousfootertext: "Nous sommes ravis de vous offrir notre assistance. Contactez-nous si vous avez besoin d'aide.",
              contacteznousfooterbtn: "Nous contacter",
              
              hometitre: "Révisez avec facilité: Boostez votre réussite avec Revizo!",
              homesoustitre: "Révisez vos cours en toute simplicité avec des quiz et évaluez-vous à l'aide de graphiques.",
              textcolore: "Plateforme de révision pour le Bac algérien.",
              googleplaybadgealt:"Badge de Google Play",
              appstorebadgealt:"Badge de App Store",
              homealt: "Une capture du dashbord de l'application",
              firstpointalt:"Icone pour préparation personnalisée",
              firstpointtexte: "Quiz personnalisés",
              secondpointalt:"Icone pour Suivi de Progrès",
              secondpointtexte: "Suivi des Progrès",
              thirdpointalt:"Icone pour Utilisation facile",
              thirdpointtexte: "Utilisation facile",
              
              servicestitre: "Quels sont les services de Revizo ?",
              servicessoustitre: "Revizo vous offre plusieurs services afin de vous aider dans votre révision.",
              firstservicealt: "Logo du premier service",
              firstservicetexte:"Création de Quiz Personnalisés selon vos besoins.",
              secondservicealt: "Logo du second service",
              secondservicetexte:"Possibilité de mettre en pause le quiz et de le reprendre quand bon vous semble.",
              thirdservicealt: "Logo du troisième service",
              thirdservicetexte: "Exercices et Sujets Diversifiés. Des questions variées vous attendent.",
              fourthservicealt: "Logo du quatrième service",
              fourthservicetexte: "Suivi des Progrès avec Graphiques pour identifier les lacunes.",
              fifthservicealt: "Logo du cinquième service",
              fifthservicetexte: "Support de contact disponible pour nous joindre en cas de problèmes.",
              sixthservicealt: "Logo du sixième service",
              sixthservicetexte: "Disponible sur téléphone, tablette et ordinateur.",
              seventhservicealt: "Logo du septième service",
              seventhservicetexte: "Différentes méthodes de paiement : BaridiMob, CCP et livraison à domicile.",
              eighthservicealt: "Logo du huitième service",
              eighthservicetexte: "Un abonnement valable jusqu'à la fin de l'année scolaire.",

              guidetitre: "Comment activer votre abonnement?",
              guidesoustitre: "Accédez à la rubrique Abonnement pour choisir votre mode de paiement préféré et bénéficier de toutes les fonctionnalités de Revizo.",
              guidealt:"Une capture de la page Abonnement de Revizo",
              instruction1alt:"Etape 1",
              instruction1texte:"Connexion à la plateforme Revizo",
              instruction2alt:"Etape 2",
              instruction2texte:"Choix de la méthode de paiement",
              instruction3alt:"Etape 3",
              instruction3texte:"Paiement de la somme de 2000 Da",
              instruction4alt:"Etape 4",
              instruction4texte:"Attente de vérification ou de livraison",   
              instruction5alt:"Etape 5",
              instruction5texte:"Activation de l'abonnement",                       
      
              subscriptionsteplink:"Commencer gratuitement",
              subscriptionstep1alt:"CCP image",
              subscriptionstep1titre:"Paiement par CCP",
              subscription1etape1: "Accédez à Revizo et prenez le numéro de CCP.",
              subscription1etape2: "Déposez 2000Da à la poste.",
              subscription1etape3: "Envoyez le reçu via l'application.",
              subscriptionstep2alt:"BaridiMob image",
              subscriptionstep2titre:"Paiement par BaridiMob",
              subscription2etape1: "Accédez à Revizo et prenez le RIP.",
              subscription2etape2: "Transferez 2000Da via Baridi Mob.",
              subscription2etape3: "Envoyez le reçu via l'application.",
              subscriptionstep3alt:"Espece image",
              subscriptionstep3titre:"Livraison à domicile",
              subscription3etape1: "Accédez à Abonnement dans Revizo.",
              subscription3etape2: "Entrez vos données de livraison.",
              subscription3etape3: "Payez le livreur 2000 Da plus les frais de livraison.",
              subscription3etape4: "Activez l'abonnement via Revizo avec le code sur la carte.",

              apercutitre:"A quoi ressemble Revizo ?",
              apercusoustitre: "Nous vous montrons ci-dessous à quoi ressemble Revizo.",
              carouselalt: "Image carousel",

              rejoigneznoustitre: "Rejoignez-nous",
              rejoigneznoustxt: "Rejoignez notre communauté pour être informé(e) des dernières actualités, offres spéciales et événements exclusifs.",

              /*termes et conditions*/
              termesetconditions: "En vous connectant, vous acceptez les ",
              termesetconditionssuite: "termes et conditions d'utilisation.",
              
              termesetconditionstitre: "Termes et Conditions",
              termesetconditionscompte: "L'utilisation de Revizo nécessite le respect de ces",

              /*Erreur de connexion email password*/
              utilisateurnontrouveerreur: "Vos données ne correspondent à aucun compte.",
              emaillinvalideerreur: "L’adresse e-mail fournie n’est pas valide.",
              mauvaismotdepasseerreur: "Le mot de passe fourni est incorrect.",
              emailexistedejaerreur: "L’adresse e-mail fournie est déjà associée à un autre compte.",
              motdepassefaibleerreur: "Le mot de passe fourni est trop faible. Il doit contenir au moins 6 caractères.",
              echecrequetenetworkerreur: "Une erreur s’est produite. Veuillez réessayer plus tard.",
              erreurautre: "Une erreur s’est produite. Veuillez réessayer plus tard.",

              /*supprimés */
              fermeeparutilisateurerreur:"La fenêtre pop-up a été fermée par l’utilisateur.",
              popupbloqueeerreur:"La fenêtre pop-up a été bloquée. Veuillez autoriser les pop-ups dans les paramètres de votre navigateur.",
              requeteannuleepopuperreur:"La demande de fenêtre pop-up a été annulée.",
              echecouverturepopuperreur:"Échec de l’ouverture de la fenêtre pop-up. Veuillez réessayer.",
              domainenonautoriseerreur:"Domaine non autorisé pour la connexion. Vérifiez la configuration de votre application.",
              operationnonsupporteedanscetenvironnementerreur:"Cette opération n’est pas prise en charge dans cet environnement.",
              /*supprimés */



              /*SUBJECT AVAILABLE OR NOT */
              available: "Disponible",
              notavailable: "Pas Disponible",


              /*Quiz Types */
              qcu: "Cours",
              definitions: "Définitions",
              cartesgeographiques: "Cartes géographiques",
              personnages:"Personnages",
              dates: "Dates historiques",


              /*FAQ landing page question */
              faqtitre: "Questions Fréquemment Posées",
              faqsoustitre: "Nous répondons à vos questions pour éloigner vos zones d'ombre.",
              qst1: "Quelle est la durée de validité de l'abonnement ?",
              ans1: "L'abonnement prend fin dès l'achèvement du baccalauréat, quelle que soit la date à laquelle vous avez effectué votre paiement.",
              qst2: "Quelles matières ne sont pas encore disponibles dans les quiz ?",
              ans2: "Actuellement, toutes les matières sont disponibles, à l'exception des mathématiques, de la langue arabe, du génie mécanique, du génie électrique, de l'italien, du tamazight et de la philosophie.",
              qst3: "Pourquoi est-il avantageux de réviser avec Revizo ?",
              ans3: "Revizo vous fournit des sujets pour vous exercer et des quiz qui représentent une méthode interactive pour tester ses connaissances, identifier les faiblesses et se préparer efficacement aux examens.",
              qst4: "Quelles sont les fonctionnalités disponibles dans la version gratuite de Revizo ?",
              ans4: "Revizo offre un accès gratuit à plus de 1000 sujets d'exercices ainsi qu'à un quiz de test en Histoire.",
            
              /*Matieres bientot disponibles */
              bientotdisponible: "Bientôt disponible",
              availablesubjecttitre: "Quelles sont les matières disponibles?",
              availablesubjectsoustitre: "Actuellement, seules ces matières sont disponibles dans les quiz, mais d'autres seront bientôt ajoutées.",

            

              /*services gratuit */
              servicesgratuitstitre: "Que propose la version gratuite ?",
              servicesgratuitssoustitre: "Revizo souhaite votre réussite, c'est pourquoi elle vous propose des services gratuits.",
           
              /*maintenance */
              textmaintenance: "Votre site Revizo est en maintenance",
              textmaintenance2: "Bientôt, vous aurez accès à votre compagnon de révision pour le bac. Restez branché !",
            },
          },
        ar: {
            translation: {

            /*Copy text btn */
            copiercodetext:"نسخ الكود",
            codecopietext:"تم نسخ الكود",

            /*toastify*/
            emaildereinitialisationsuccess: "!تم إرسال البريد الإلكتروني بنجاح",

            /*wilaya delivery not available */
            textwilayanotavailable: "*** توصيل غير متاح في هذه الولاية. ***",


            /*taille recu depassée erreur */
            sizeexceedederror: "لا يجب أن يتجاوز حجم الصورة الحد الأقصى لـ 3 ميجابايت.",


            /*Popup verifier email login */
            enregistrerbtn: "حفظ",
            savequiztext: "يسمح لك حفظ الإجابات بالاستمرار من حيث توقفت في المراجعة القادمة.",
            savequiztitle: "هل ترغب في حفظ إجاباتك؟",

            /*PopupVerifyEmail */        
            renvoyertext:"إعادة إرسال",
            fermertext :"إغلاق",
            verifyemailtext1:"تم إرسال رابط التأكيد إلى البريد الإلكتروني",
            verifyemailtext2:"لتأكيد حسابك، يرجى الاطلاع على صندوق الوارد الخاص بك.",
            verifyemailtitle: "يرجى تأكيد عنوان بريدك الإلكتروني",

            /*Login */
            motdepasse: "كلمة السر",
            motdepasseoublie: "نسيت كلمة السر؟",
            seconnecterbtn:"تسجيل الدخول",
            ou: 'أو',
            vousnavezpasdecompte:"ليس لديك حساب؟",
            sinscrire: "إنشاء حساب.",
            inscrivezvous: "سجّل نفسك",
            inscrivezvoustext: "أدخل بريدك الإلكتروني وكلمة السر لإنشاء حساب.",
            vousavezdejauncompte: "هل لديك حساب؟",
            seconnectertext: "تسجيل الدخول.",
            motdepasseoublietitre: "إعادة تعيين كلمة المرور",
            motdepasseoublietext: "ستتلقى رسالة بريد إلكتروني لإعادة تعيين كلمة المرور في صندوق الوارد الخاص بك.",
            reinitialiserbtn: "إعادة تعيين",
            motdepassereinitialise: "هل تمت إعادة تعيين كلمة المرور؟",

            /*connexion signupotp */
            connexiontitre: "تسجيل الدخول",
            connexionsoustitre1: "منصة ريفيزو تساعدك على اختبار معرفتك للتحضير لامتحان البكالوريا.",
            continuerbtn: "متابعة",
            
            continueravecgooglebtn: "متابعة باستخدام Google",
            connexionsoustitre2: "لقد أرسلنا لك رمز التحقق على رقم هاتفك.",

            
            /*connexion signuform */
            connexionformtitre: "الخطوة الأخيرة!",
            connexionformsoustitre: "أضف معلوماتك لاستكمال التسجيل.",
            commencerbtn:"بدء",
            warningtrack: "اختر شعبتك أثناء التسجيل، حيث يعتمد محتوى المنصة على هذا الاختيار ولا يمكن تعديله لاحقًا.",       

            

              //sidebar navlink
              dashboard: 'لوحة المتابعة',
              quiz: 'كويز',
              vosquiz: 'كويزاتك',
              creerquiz: 'إنشاء كويز',
              sujets: 'مواضيع',
              abonnement: 'اشتراك',
              compte: 'حساب',
              contacteznous: 'اتصل بنا',
              deconnexion: 'تسجيل الخروج',

              /*Deconnexion */
              titredeconnexion: 'تسجيل الخروج',
              soustitredeconnexion: 'هل ترغب في تسجيل الخروج؟', 
              annulerbtn: 'إلغاء',
              confirmerdeconnexionbtn: 'الخروج',

              /*Dashboard */
              dashboardtitre: 'لوحة المتابعة',
              dashboardsoustitre: 'قيموا نتائجكم وحددوا نقاط الضعف لتتمكنوا من تحسين أدائكم.',
              meilleurenote: 'أفضل علامة',
              pirenote: 'أسوأ علامة',
              nombretotalde: 'العدد الإجمالي لـ',
              quizfait: 'الكويزات المنجزة',
              active: 'مفعّل',
              nonactive: 'غير مفعل',
              enattente: 'في انتظار',
              vosscores: 'نتائجكم',
              dernierquizfait: 'آخر كويز تم إجراؤه',
              reprendre: 'متابعة',
              aucunquizfait:'لم يتم إجراء أي كويز',
              commencermaintenant: 'ابدأ الآن',
              performanceshebdomadaires: 'الأداء الأسبوعي',
              dimanche: 'الأحد',
              lundi: 'الاثنين',
              mardi: 'الثلاثاء',
              mercredi: 'الأربعاء',
              jeudi: 'الخميس',
              vendredi: 'الجمعة',
              samedi: 'السبت',
              score: 'النقاط',
              date: format(new Date(), "EEEE, d MMMM yyyy", {locale: ar}), /*today date */
              dateFormat: "'تم إنشاءه في' d MMMM yyyy ' على الساعة' HH:mm:ss", /*last quiz done */

             /*Vos quiz */
             vosquizsoustitre:'اكتشف الكويزات التي يمكنك إكمالها أو استئنافها.',
             supprimer: 'حذف',
             recommencer : 'إعادة',
             page: 'الصفحة',
             aucunquiztrouve: ".لم يتم العثور على أي كويز. ابدأ بإنشاء واحد",
             datecreationquiz: "'تم إنشاؤه في' d/MM/yyyy 'الساعة' HH:mm",

            /*Le quiz*/
              question:"سؤال",
              qstquivantebtn: "السؤال التالي",
              arreterquizbtn: "إيقاف الكويز",
              suivantbtn: "التالي",
              arreterbtn: "توقيف",
              resultatfinal: "النتيجة النهائية",
              resultatscoretexte1: "لقد حصلت على درجة",
              resultatscoretexte2: "من",
              resultatscoretexte3: "في الأسئلة التي أجبت عليها.",
              quitterquizbtn:"الخروج",
              quitterbtn: "الخروج",
              refairebtn: "إعادة",
              correcte: "\t\t\tصحيح",
              faux: "\t\t\tخاطئ",

              /*Filtre */
              filtrermatieres: 'المواد',
              filtrertrimestres: 'الفصول',
              filtrertypes: 'الأنواع',
              filtrerannées: 'السنوات',

             /*supprimer quiz */
             supprimertitre: 'حذف الكويز',
             supprimersoutitre: 'هل ترغب في حذف الكويز؟',
              
             /* Toast de suppression */
             supptoasttexte: 'تم حذف الكويز بنجاح',

             /*Creer quiz */
             creerquizsoustitre:"أنشئ كويز وفقًا لاحتياجاتك وتفضيلاتك.",
             creerquizetape1:'الخطوة 1: اختر المادة',
             creerquizetape2:'الخطوة 2: اختر درسًا أو أكثر',
             creerquizetape3:'الخطوة 3: اختر عدد الأسئلة',
             creerquizetape4:'الخطوة 4: اختر نوع الكويز ',
             btnprecedent: 'السابق',
             btnsuivant: 'التالي',
             btncreerquiz: 'إنشاء الكويز',
             illimite: 'غير محدودة',
             accesinterdittitre: "الوصول ممنوع",
             accesinterditnonregletexte: "يجب عليك تفعيل اشتراكك قبل أن تتمكن من إنشاء كويز. يمكنك حل المواضيع مجاناً.",
             accesinterditattentelabel: "ممارسة مجانية",
             accesinterditnonreglelabel: "دفع الاشتراك",
             accesinterditattentetexte: "يرجى الانتظار أثناء تفعيل حسابك قبل أن تتمكن من إنشاء كويز.",
             creele : "تم الإنشاء في",
             textà: 'على الساعة',

             /*Sujets */
             sujetsoustitre: "تمرن بمساعدة هذه المواضيع وصفّيها حسب احتياجاتك.",
             examen: 'امتحان',
             devoir: 'فرض',
             bac: 'باك',
             trimestre1: 'الفصل 1',
             trimestre2: 'الفصل 2',
             trimestre3: 'الفصل 3',
             aucunsujettrouve: "لم يتم العثور على أي موضوع.",

             /*Abonnement */
             abonnementsoustitre: 'اختر طريقة الدفع التي تناسبك لتفعيل الاشتراك.',
             offreannuelle: 'عرض سنوي',
             abonnementunite: 'دج / شخص',
             abonnementunite2: 'دج',


             abonnementpremieravantage: 'أكثر من 1000 موضوع.',
             abonnementsecondavantage: 'أسئلة كويز غير محدودة.',
             abonnementtoisiemeavantage: "أسئلة مستهدفة حسب المادة والدرس.",
             abonnementquatriemeavantage: "إحصائيات للتقييم.",
             abonnementcinquiemeavantage: 'متاح على جميع أجهزتكم.',
             abonnementsixiemeavantage: "اشتراك صالح طوال السنة الدراسية.",
             abonnementseptiemeavantage : "خدمة عملاء فعّالة للرد على جميع أسئلتكم.",

             abonnementregletitre: '!تم قبول دفعتك', 
             abonnementreglesoutitre: ".استفيد من المنصة باختبار معرفتك",
             abonnementattentetitre: '!نحن نعالج معلوماتك',
             abonnementattentesoutitre: ".استكشف تماريننا المجانية في انتظار تسليم رمز التفعيل الخاص بك",
             codedejadisponible: "هل لديك رمز التفعيل؟" ,
             methodepaiementtitre : 'طرق الدفع:',
             premieremethodepaiement: 'الدفع عبر BaridiMob',
             secondemethodepaiement: 'الدفع عبر CCP',
             troisiememethodepaiement: 'الدفع نقدًا',
             baridimobtexte: 'أجرِ الدفع ويرجى إرسال الإيصال.',
             ccptexte: 'أجرِ الدفع ويرجى إرسال الإيصال.',
             especetexte:"استلموا رمز التفعيل في منزلكم وادفعوا",
             especetextesuite:"دينار عند التوصيل.",
             aucuneimageselectionnee: 'لم يتم اختيار أي صورة',
             envoyerbtn: 'إرسال',
             adresseplaceholder:'شارع 01 دو سافوا، حيدرة 16035',
             telephonelabel:'رقم الهاتف',
             wilayalabel: 'الولاية',
             adresselabel: 'عنوانك',
             selectionnezwilaya: 'اختر ولايتك',
             waitingconfirmationregletexte: "استمتع بالمنصة من خلال اختبار معرفتك.",
             waitingconfirmationregletitre: "تم تأكيد دفعتك!",
             waitingconfirmationreglealt:"صورة توضيحية لتأكيد الدفع",
             waitingconfirmationattentetitre: "نحن نعالج معلوماتك!",
             waitingconfirmationattentealt:"صورة توضيحية لانتظار تأكيد الدفع",
             waitingconfirmationattenterecutexte: "استكشف تماريننا المجانية في انتظار معالجة إيصالك.",
             waitingconfirmationattentelivraisontexte: "استكشف تماريننا المجانية في انتظار توصيل رمز التفعيل الخاص بك.",

             /*Activation code */
             activationcodetitre: 'قم بتفعيل اشتراكك',
             activationcodesoustitre: "إذا كنت قد استلمت بطاقة التفعيل، أدخل الرمز المرفق لتفعيل اشتراكك.",
             verifiercodebtn: "تحقق من الرمز",
             nouscontactertexte: "هل لديك مشكلة مع الرمز؟ اتصل بنا",
             activationcodesoustitreregle: "تم تفعيل الاشتراك إلى غاية نهاية العام الدراسي",
             erreuractivation: "رمز التفعيل خاطئ.",

             /*Compte */
             comptesoustitre: 'تحقق من معلوماتك وقم بتعديلها إذا لزم الأمر.',
             compteinformationspersonnelles: 'المعلومات الشخصية',
             comptevotreabonnement: 'اشتراكك',
             prenomlabel: 'الاسم',
             nomlabel:'اللقب',
             lyceelabel:'الثانوية',
             filierelabel:'الفرع',
             emaillabel: 'البريد الإلكتروني',
             modifierbtn: 'تعديل',
             sauvegarderbtn: 'حفظ',
             abonnementnonregletexte: ".لم تفتعل أي اشتراك",
             abonnementnonregletextelien: '.تفعيل اشتراك',
             abonnementregletexte: ".قمت بتفعيل اشتراك صالح إلى غاية النهاية الدراسية",
             abonnementattentetexte: ".جاري معالجة الدفع", // en attente de traitement ?
             vosinformationsbtn: 'معلوماتك',
             selectionnezfiliere: 'اختر تخصص البكالوريا الخاص بك',
             comptestatutregle:"لقد قمت بتفعيل اشتراك صالح حتى نهاية العام الدراسي.",
             comptestatutnonregle:"لم تفتعل أي اشتراك.",
             comptestatutnonreglesuite:"تفعيل اشتراك.",
             comptestatutattente:"الرجاء الانتظار لتسليم بطاقتك أو معالجة الإيصال.",

             /*filiere */
             scientifique: "علوم التجريبية",
             mathematiques: "رياضيات",
             mathtechniques: "تقني رياضي",
             gestioneteconomie: "تسيير و اقتصاد",
             languesetrangeres: "لغات أجنبية",
             litteratureetphilosophie: "آداب و فلسفة",


            /*contact */
             contactsoustitre: 'اتصل بنا إذا كانت لديك مشكلة.',
             contactemailplaceholder: 'البريد الإلكتروني',
             contactobjetplaceholder: 'الموضوع',
             contactmessageplaceholder: 'رسالتك...',
             contacttoast: '.تم إرسال الرسالة بنجاح',
             contacttoastechec: ".فشل في إرسال الرسالة",

             /*Matieres */
             philosophie: 'الفلسفة',
             comptabilite: 'التسيير المحاسبي والمالي',
             economietmanagement: 'الاقتصاد والمناجمنت',
             mathematiques:'الرياضيات',
             physique: 'العلوم الفيزيائية',
             sciencesnaturelles: 'علوم الطبيعة والحياة',
             histoire: 'التاريخ',
             geographie:'الجغرافيا',
             geniemecanique: 'الهندسة الميكانيكية',
             genieelectrique: 'الهندسة الكهربائية',
             geniedesprocedes: 'هندسة الطرائق',
             geniecivil: 'الهندسة المدنية',
             tamazight: 'اللغة الأمازيغية',
             anglais: 'اللغة الإنجليزية',
             francais: 'اللغة الفرنسية',
             espagnol: 'اللغة الإسبانية',
             italien: 'اللغة الإيطالية',
             arabe: 'اللغة العربية',
             allemand: 'اللغة الألمانية',
             educationislamique: 'التربية الإسلامية',
             droit: 'القانون',

             /*skeleton */
             matiereskeleton: 'مادة',

            /*wilaya */
            adrar: "أدرار",
            aindefla: "عين الدفلى",
            aintemouchent: "عين تموشنت",
            alger: "الجزائر",
            annaba: "عنابة",
            batna: "باتنة",
            bechar: "بشار",
            bejaia: "بجاية",
            beniabbes: "بني عباس",
            biskra: "بسكرة",
            blida: "البليدة",
            bordjbadjimokhtar: "برج باجي مختار",
            bordjbouarreridj: "برج بوعريريج",
            bouira: "البويرة",
            boumerdes: "بومرداس",
            chlef: "الشلف",
            constantine: "قسنطينة",
            djanet: "جانت",
            djelfa: "الجلفة",
            elbayadh: "البيض",
            elmeniaa: "المنيعة",
            elmghair: "المغير",
            eloued: "الوادي",
            eltarf: "الطارف",
            ghardaia: "غرداية",
            guelma: "قالمة",
            illizi: "إليزي",
            inguezzam: "إن قزام",
            insalah: "إن صالح",
            jijel: "جيجل",
            khenchela: "خنشلة",
            laghouat: "الأغواط",
            medea: "المدية",
            mila: "ميلة",
            mostaganem: "مستغانم",
            msila: "المسيلة",
            mascara: "معسكر",
            naama: "النعامة",
            ouleddjellal: "أولاد جلال",
            oumelbouaghi: "أم البواقي",
            ourgla: "ورقلة",
            oran: "وهران",
            relizane: "غليزان",
            saida: "سعيدة",
            setif: "سطيف",
            sidibelabbes: "سيدي بلعباس",
            skikda: "سكيكدة",
            soukahras: "سوق أهراس",
            tamanrasset: "تمنراست",
            tebessa: "تبسة",
            tiaret: "تيارت",
            timimoun: "تيميمون",
            tindouf: "تندوف",
            tipaza: "تيبازة",
            tissemsilt: "تسمسيلت",
            tiziouzou: "تيزي وزو",
            touggourt: "تقرت",
            tlemcen: "تلمسان" ,
             
            /*Notification*/
            aucunenotification: "لا يوجد رسالة",
              

            /*Not found */
            notfoundtext: "الصفحة التي تبحث عنها غير موجودة! نقترح عليك العودة إلى الصفحة الرئيسية.",
            notfoundtitle: "الصفحة غير موجودة",
            notfoundlabel: "العودة إلى الصفحة الرئيسية",
               
            /*page vitrine */
            accueilnavlink: "الرئيسية",
            servicesnavlink: "الخدمات",
            paimentnavlink: "الدفع",
            aperçunavlink: "نظرة عامة",
            contactnavlink: "الاتصال",
            connexionbtn: "تسجيل الدخول",

            commencergratuitement: "ابدأ مجاناً",

            contacteznousfootertext: "نحن مسرورون بتقديم المساعدة لكم. تواصلوا معنا إذا كنتم بحاجة إلى مساعدة.",
            contacteznousfooterbtn: "التواصل معنا",

            rejoigneznoustitre: "انضم إلينا",
            rejoigneznoustxt: "انضم إلينا لتكون على علم بآخر الأخبار، والعروض الخاصة، والفعاليات الحصرية.",


            hometitre: "راجع بسهولة: قوي فرصك في النجاح مع ريفيزو.",
            homesoustitre: "قم بمراجعة دروسك بكل سهولة باستخدام الكويز وقم بتقييم نفسك من خلال الرسوم البيانية.",
            textcolore: "منصة مراجعة لامتحان البكالوريا الجزائري.",
            googleplaybadgealt: "شارة Google Play",
            appstorebadgealt: "شارة App Store",
            homealt: "لقطة من لوحة تحكم المنصة",
            firstpointalt: "رمز للتحضير الشخصي",
            firstpointtexte: "تحضير شخصي",
            secondpointalt: "رمز لمتابعة التقدم",
            secondpointtexte: "متابعة التقدم",
            thirdpointalt: "رمز لسهولة الاستخدام",
            thirdpointtexte: "سهولة الاستخدام",

            servicestitre: "ما هي خدمات ريفيزو؟",
            servicessoustitre: "تقدم لك ريفيزو العديد من الخدمات لمساعدتك في مراجعاتك.",
            firstservicealt: "شعار الخدمة الأولى",
            firstservicetexte: "إنشاء كويز تفاعلي حسب احتياجاتك.",
            secondservicealt: "شعار الخدمة الثانية",
            secondservicetexte: "إمكانية إيقاف الكويز واستئنافه في أي وقت.",
            thirdservicealt: "شعار الخدمة الثالثة",
            thirdservicetexte: "تنوع في التمارين والمواضيع. أسئلة متنوعة تنتظرك.",
            fourthservicealt: "شعار الخدمة الرابعة",
            fourthservicetexte: "متابعة التقدم عبر الرسوم البيانية لتحديد النقاط الضعيفة.",
            fifthservicealt: "شعار الخدمة الخامسة",
            fifthservicetexte: "دعم الاتصال متاح للتواصل معنا في حالة وجود مشاكل.",
            sixthservicealt: "شعار الخدمة السادسة",
            sixthservicetexte: "متاح على الهاتف والجهاز اللوحي والكمبيوتر.",
            seventhservicealt: "شعار الخدمة السابعة",
            seventhservicetexte: "طرق الدفع متنوعة: بريدي موب، حساب البريد الجاري، وتوصيل إلى المنزل.",
            eighthservicealt: "شعار الخدمة الثامنة",
            eighthservicetexte: "اشتراك صالح حتى نهاية العام الدراسي.",

            guidetitre: "كيفية تفعيل اشتراكك؟",
            guidesoustitre: "انتقل إلى قسم الاشتراك لاختيار وسيلة الدفع المفضلة لديك والاستفادة من جميع ميزات ريفيزو.",
            guidealt: "لقطة من صفحة الاشتراك في ريفيزو",
            instruction1alt: "الخطوة 1",
            instruction1texte: "تسجيل الدخول إلى منصة ريفيزو",
            instruction2alt: "الخطوة 2",
            instruction2texte: "اختيار وسيلة الدفع",
            instruction3alt: "الخطوة 3",
            instruction3texte: "دفع مبلغ 2000 دج",
            instruction4alt: "الخطوة 4",
            instruction4texte: "انتظار التحقق أو التسليم",
            instruction5alt: "الخطوة 5",
            instruction5texte: "تفعيل الاشتراك",

            subscriptionsteplink: "ابدأ مجانًا",
            subscriptionstep1alt: "صورة CCP",
            subscriptionstep1titre: "الدفع عن طريق حساب البريد الجاري",
            subscription1etape1: "ادخل إلى ريفيزو واحصل على رقم CCP.",
            subscription1etape2: "أودع 2000 دج في مكتب البريد.",
            subscription1etape3: "أرسل الإيصال عبر المنصة.",
            subscriptionstep2alt: "صورة BaridiMob",
            subscriptionstep2titre: "الدفع عن طريق بريدي موب",
            subscription2etape1: "ادخل إلى ريفيزو واحصل على ال RIP.",
            subscription2etape2: "قم بتحويل 2000دج عبر بريدي موب.",
            subscription2etape3: "أرسل الإيصال عبر المنصة.",
            subscriptionstep3alt: "صورة نقدية",
            subscriptionstep3titre: "توصيل إلى المنزل",
            subscription3etape1: "ادخل إلى قسم الاشتراك في Revizo.",
            subscription3etape2: "أدخل معلومات التسليم الخاصة بك.",
            subscription3etape3: "ادفع للسائق 2000 دج بالإضافة إلى تكاليف التوصيل.",
            subscription3etape4:"قم بتفعيل الاشتراك عبر ريفيزو باستخدام الرمز الموجود على البطاقة.",

            apercutitre: "كيف يبدو ريفيزو؟",
            apercusoustitre: "نريك فيما يلي كيف يبدو ريفيزو.",
            carouselalt: "صورة دورية",

            /*termes et conditions*/
            termesetconditions: "عندما تقوم بتسجيل الدخول، فإنك توافق على",
            termesetconditionssuite: "\tالشروط والأحكام للاستخدام.",
            
            termesetconditionstitre: "الشروط والأحكام",
            termesetconditionscompte: "يتطلب استخدام ريفيزو احترام هذه",
            
            /*Erreur de connexion email password*/
            utilisateurnontrouveerreur: "بياناتك لا تتطابق مع أي حساب.",
            emaillinvalideerreur: "البريد الإلكتروني المقدم غير صالح.",
            mauvaismotdepasseerreur: "كلمة المرور المقدمة غير صحيحة.",
            emailexistedejaerreur: "البريد الإلكتروني المقدم مرتبط بحساب آخر.",
            motdepassefaibleerreur: "كلمة المرور التي قدمتها ضعيفة جداً. يجب أن تحتوي على ما لا يقل عن 6 أحرف",
            echecrequetenetworkerreur: "حدث خطأ في الطلب الشبكي. يرجى المحاولة مرة أخرى في وقت لاحق.",
            erreurautre: "حدث خطأ. يرجى المحاولة مرة أخرى في وقت لاحق.",

            /*supprimés */
            fermeeparutilisateurerreur: "تم إغلاق النافذة المنبثقة بواسطة المستخدم.",
            popupbloqueeerreur: "تم حظر النافذة المنبثقة. يرجى السماح بالنوافذ المنبثقة في إعدادات المتصفح الخاص بك.",
            requeteannuleepopuperreur: "تم إلغاء طلب النافذة المنبثقة.",
            echecouverturepopuperreur: "فشل في فتح النافذة المنبثقة. يرجى المحاولة مرة أخرى.",
            domainenonautoriseerreur: "النطاق غير مصرح به للاتصال. يرجى التحقق من إعدادات تكوين تطبيقك.",
            operationnonsupporteedanscetenvironnementerreur: "هذه العملية غير مدعومة في هذا البيئة.",            
            /*supprimés */

            /*SUBJECT AVAILABLE OR NOT */
            available: "متوفر",
            notavailable: "غير متوفر",

             /*Quiz Types */
             qcu: "الدروس",
             definitions: "مصطلحات",
             cartesgeographiques: "خرائط جغرافية",
             personnages:"شخصيات",
             dates: "تواريخ",

              /*FAQ landing page question */
              faqtitre: "الأسئلة المتكررة",
              faqsoustitre: "نحن هنا للرد على أسئلتكم وتبديد أي غموض.",
              qst1: "ما هو مدى صلاحية الاشتراك؟",
              ans1: "ينتهي الاشتراك فور انتهاء امتحان البكالوريا، بغض النظر عن تاريخ الدفع الذي قمت به.",
              qst2: "ما هي المواد الغير متوفرة بعد في الQuiz؟",
              ans2: "حالياً، جميع المواد متاحة، باستثناء الرياضيات، واللغة العربية، والهندسة الميكانيكية، والهندسة الكهربائية، واللغة الإيطالية، واللغة الأمازيغية، والفلسفة.",
              qst3: "ما الفائدة من المراجعة باستخدام Revizo؟",
              ans3: "يوفر لك Revizo مواضيع للممارسة و Quiz تمثل طريقة تفاعلية لاختبار معرفتك، وتحديد نقاط الضعف، والتحضير بفعالية للاختبارات.",
              qst4: "ما هي الخدمات المتاحة في النسخة المجانية من Revizo؟",
              ans4: "Revizo يوفر أكثر من 1000 موضوع مجانًا بالإضافة إلى Quiz واحد في مادة التاريخ.",

              /*Matieres bientot disponibles */
              bientotdisponible: "متوفر قريبًا",
              availablesubjecttitre: "ما هي المواد المتوفرة حالياً؟",
              availablesubjectsoustitre: "حالياً، هذه هي المواد المتاحة في الQuiz، ولكن سيتم إضافة مواد أخرى قريباً.",


              /*services gratuit */
              servicesgratuitstitre: "ما هي الخدمات المجانة؟",
              servicesgratuitssoustitre: "ريفيزو تتمنى لك النجاح، ولهذا تقدم لك خدمات مجانية.",


              /*maintenance */
              textmaintenance: "موقع Revizo تحت الصيانة",
              textmaintenance2: "قريباً ستتمكنون من الوصول إلى رفيقكم في المراجعة للبكالوريا. ابقوا متابعين!"
          },
        },

    },
    lng: 'ar',
    fallbackLng: 'ar',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
