import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './LandingPage.css'; // Assurez-vous d'ajuster le chemin vers le fichier CSS

import Apercu1 from '../../images/apercu1.png';
import Apercu2 from '../../images/apercu2.png';
import Apercu3 from '../../images/apercu3.png';
import Apercu4 from '../../images/apercu4.png';
import Apercu5 from '../../images/apercu5.png';
import Apercu6 from '../../images/apercu6.png';
import Apercu7 from '../../images/apercu7.png';

import Apercu1Ar from '../../images/apercu1ar.png';
import Apercu2Ar from '../../images/apercu2ar.png';
import Apercu3Ar from '../../images/apercu3ar.png';
import Apercu4Ar from '../../images/apercu4ar.png';
import Apercu5Ar from '../../images/apercu5ar.png';
import Apercu6Ar from '../../images/apercu6ar.png';
import Apercu7Ar from '../../images/apercu7ar.png';
import { useTranslation } from 'react-i18next';

const images = [
  Apercu1,
  Apercu2,
  Apercu3,
  Apercu4,
  Apercu5,
  Apercu6,
  Apercu7
];

const imagesArabe = [
  Apercu1Ar,
  Apercu2Ar,
  Apercu3Ar,
  Apercu4Ar,
  Apercu5Ar,
  Apercu6Ar,
  Apercu7Ar
];

const Carousel = () => {
  const { t, i18n } = useTranslation();

  const settings = {
    /*autoplay: true,*/
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    className: "slick-carousel"
  };

  return (
    <div className="carousel">
      {i18n.language === 'ar' ? (
        <>
          <Slider {...settings}>
            {imagesArabe.map((image, index) => (
              <div key={index}>
                <img src={image} alt={t('carouselalt')} />
              </div>
            ))}
          </Slider>

        </>
      ) : (
        <>
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index}>
                <img src={image} alt={t('carouselalt')} />
              </div>
            ))}
          </Slider>

        </>
      )}
    </div>
  );
};

export default Carousel;

