import './Maintenance.css'
import maintenanceImage from "../../images/maintenance.png"
import { useTranslation } from 'react-i18next';

const Maintenance = () => {

  const { t, i18n } = useTranslation();

  return (
        <div className="container-maintenance">
            <div className={`content-maintenance ${i18n.language === 'ar' ? 'right-to-left' : ''} ` } >
            <img src={maintenanceImage} alt="image" className="image-maintenance" loading="lazy" width="350" height="350"/>
            <h3 className="title-maintenance">{t('textmaintenance')}</h3>
            <p className="description-maintenance">
              {t('textmaintenance2')}
            </p>
            </div>
        </div>

  )
}

export default Maintenance