import LoadingBtn from '../Loading/LoadingBtn';
import './Button.css'

const Button = ({ 
    onClick, 
    disabled, 
    label, 
    className, 
    type,
    icon,
    loading
    }) => {
    return (
        <button
            className={className}
            disabled={disabled}
            onClick={onClick}
            type={type}
        >
            {loading && <LoadingBtn/>}
            {!loading && icon}
            {label}
        </button>
    )
}

export default Button;
