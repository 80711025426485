

import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

import LandingPage from "./pages/LandingPage/LandingPage";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import Maintenance from "./pages/Maintenance/Maintenance";


function App() {

//eviter les retours en arriere dans les liens
const navigateWithReplace = useNavigate({
  replace: true,
});



  return (
    <>

      <Routes>
        
        <Route path="/"  element={<LandingPage navigate={navigateWithReplace}/>} />
        <Route path="/*" element={<Navigate to="/"/>} />

        <Route path="/app" element={<Maintenance/>} />

        <Route path="/termes-et-conditions" element={<TermsAndConditions />} />

      </Routes>

   </>
  );
}

export default App;


