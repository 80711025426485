import './LandingPage.css'

import LandingPageImg from '../../images/landing-page-img1.webp'
import LandingPageImgAr from '../../images/landing-page-img1ar.webp'


import firstPoint from '../../images/first-point.png'
import secondPoint from '../../images/second-point.png'
import thirdPoint from '../../images/third-point.png'

import guideImg2 from '../../images/guide-img2.png'
import guideImg2ar from '../../images/guide-img2ar.png'

import FirstServiceImg from '../../images/first-service-img.png'
import SecondServiceImg from '../../images/second-service-img.png'
import ThirdServiceImg from '../../images/third-service-img.png'
import FourthServiceImg from '../../images/fourth-service-img.png'
import FifthServiceImg from '../../images/fifth-service-img.png'
import SixthServiceImg from '../../images/sixth-service-img.png'
import SeventhServiceImg from '../../images/seventh-service-img.png'
import EighthServiceImg from '../../images/eighth-service-img.png'


import Carousel from './Carousel'


import CCPImg from '../../images/paiement-method1.webp'
import BARIDIMOBImg from '../../images/paiement-method2.webp'
import PaiementCash from '../../images/paiement-method3.webp'

import ccpLogo from '../../images/algerie-poste.png'
import baridimobLogo from '../../images/baridimob.png'
import especeLogo from '../../images/espece-logo.png'


import Cercle1 from '../../images/cercle1.png'
import Cercle2 from '../../images/cercle2.png'
import Cercle3 from '../../images/cercle3.png'
import Cercle4 from '../../images/cercle4.png'
import Cercle5 from '../../images/cercle5.png'


import pinkCircle1 from "../../images/circle1-pink.png"
import pinkCircle2 from "../../images/circle2-pink.png"
import pinkCircle3 from "../../images/circle3-pink.png"
import pinkCircle4 from "../../images/circle4-pink.png"

import freeService1 from "../../images/freeservice1.png"
import freeService2 from "../../images/freeservice2.png"
import freeService1arabe from "../../images/freeservice1arabe.png"
import freeService2arabe from "../../images/freeservice2arabe.png"

import {RiFacebookCircleLine} from "react-icons/ri";
import {FaInstagram, FaLinkedin, FaTiktok} from "react-icons/fa";

import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom'
import { useLanguage } from '../../context/LanguageContext'

import Button from '../../components/Buttons/Button'
import { ToastContainer } from "react-toastify"
import TermsConditions from '../../components/TermsConditions/TermsConditions'

/*import RevizoLogo from '../images/Rlogoblack12.png' */
import RevizoLogo from '../../images/logo-landing-page5.webp' 

import SelectLanguageFloat from '../../components/SelectLanguageFloat/SelectLanguageFloat'


import { useEffect, useState } from 'react'

import AOS from 'aos';
import 'aos/dist/aos.css';

import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { FaLongArrowAltRight, FaLongArrowAltLeft  } from "react-icons/fa";

import { VscCheck } from "react-icons/vsc";

const LandingPage = () => {

    const { t , i18n } = useTranslation();
    const {changeLanguage} = useLanguage()

    const navigate = useNavigate();

    const navigateToSignIn = ()=>{
        navigate('/app')
    }

    /*AOS*/
    AOS.init();
    
  
    /*FAQ*/
        const [activeIndex, setActiveIndex] = useState(null);
      
        const toggleAccordion = (index) => {
          setActiveIndex(activeIndex === index ? null : index);
        };
      
        const faqData = [
          {
            question: t('qst1'),
            answer: t('ans1'),
          },
          {
            question: t('qst3'),
            answer: t('ans3')
          },
          {
            question: t('qst4'),
            answer: t('ans4')
          },
        ];


        //color change
        const pastelColors = [
            '#ffecd0', 
            '#ffe3b9', 
            '#ffe5ae', 
            '#fccb8f', 
            '#ffffa8', 
            '#ffff87', 
            '#ffdf67', 
            '#e3f188', 
            '#c6e368', 
            '#bcf4cb', 
            '#98FB98', 
            '#a0e2b3',   
            '#ffdbe1',
            '#ffc9d2',
   
        ]; // Liste de couleurs pastel



        //color animation
        const [colorIndex, setColorIndex] = useState(0); // Définir l'index de couleur initiale

        useEffect(() => {
            const intervalId = setInterval(() => {
                // Incrémentez l'index de couleur pour afficher la prochaine couleur dans l'ordre
                setColorIndex(prevIndex => (prevIndex + 1) % pastelColors.length);
            }, 3000); // Changer toutes les trois secondes
    
            return () => clearInterval(intervalId); // Nettoyer le setInterval lors du démontage du composant
        }, []); // Le tableau vide signifie que useEffect s'exécutera une fois après le premier rendu
    
        const currentColor = pastelColors[colorIndex];
  






  return (
   
    <div className='unselectable'>

            {/* HEADER */}  
            
                <header className='landing-page-header'>
                    <div className='landing-page-logo'>
                        <img src={RevizoLogo} className="logo-revizo-image" alt="logo de Revizo"/>
                        <span>Revizo</span>
                    </div>

                    <div className='landing-page-btn'>
                        <Button label={t('connexionbtn')}  className="green-bg btn-rnd btn-decoration" disabled={null} onClick={navigateToSignIn} type=""/>
                    </div>
                </header>


        

            {/* Accueil */}
            <section 
                className={`home-section ${i18n.language === 'ar' ? 'right-to-left' : ''} ` } 
            >
                <div className='landing-page-home'>
                    <div className='home-left'>
                        <div className='home-text-colored'  style={{ backgroundColor: currentColor}}>
                            {t('textcolore')}
                        </div>
                        <div className='home-text'>
                            <div className='home-title'>{t('hometitre')}</div>
                            <div className='home-subtitle'>{t('homesoustitre')}</div>
                            <div className='home-btn'>
                                <Button 
                                    onClick={navigateToSignIn}  
                                    label={t('commencergratuitement')}
                                    className="green-bg btn-decoration" 
                                />
                            </div>
                        </div>
                    </div>

                    <div  className="home-right">
                        {
                        i18n.language === 'ar' ?
                            <img src={LandingPageImgAr} alt="Illustration"/>
                        :
                            <img src={LandingPageImg} alt="Illustration"/>
                        }
                    </div>
       
                </div>
                <ul className='postive-points'>
                    <li>
                        <i><img src={firstPoint} alt={t('firstpointalt')}/></i>
                        <span>{t('firstpointtexte')}</span>
                    </li>
                    <li>
                        <i><img src={secondPoint} alt={t('secondpointalt')}/></i>
                        <span>{t('secondpointtexte')}</span> 
                    </li>
                    <li>
                        <i><img src={thirdPoint} alt={t('thirdpointalt')}/></i>
                        <span>{t('thirdpointtexte')}</span>
                    </li>
                </ul>
            </section>


            {/*Services */}
            <section 
                className={`services-section ${i18n.language === 'ar' ? 'right-to-left' : ''}`} 
            >
                <div className='section-text'>
                    <div className='section-title'>{t('servicestitre')}</div>
                    <div className='section-subtitle'>{t('servicessoustitre')}</div>
                </div>
                <ul className='services'>
                    <li className='first-service'>
                        <img src={FirstServiceImg} alt={t('firstservicealt')} className='first-service-icon'/>
                        <span>{t('firstservicetexte')}</span>
                    </li>
                    <li className='second-service'>
                        <img src={SecondServiceImg} alt={t('secondservicealt')} className='second-service-icon'/>
                        <span>{t('secondservicetexte')}</span>
                    </li>
                    <li className='third-service'>
                        <img src={ThirdServiceImg} alt={t('thirdservicealt')} className='third-service-icon'/>
                        <span>{t('thirdservicetexte')}</span>
                    </li>
                    <li className='fourth-service'>
                        <img src={FourthServiceImg} alt={t('fourthservicealt')} className='fourth-service-icon'/>
                        <span>{t('fourthservicetexte')}</span>
                    </li>
                    <li className='fifth-service'>
                        <img src={FifthServiceImg} alt={t('fifthservicealt')} className='fifth-service-icon'/>
                        <span>{t('fifthservicetexte')}</span>
                    </li>
                    <li className='sixth-service'>
                        <img src={SixthServiceImg} alt={t('sixthservicealt')} className='sixth-service-icon'/>
                        <span>{t('sixthservicetexte')}</span>
                    </li>
                    <li className='seventh-service'>
                        <img src={SeventhServiceImg} alt={t('seventhservicealt')} className='seventh-service-icon'/>
                        <span>{t('seventhservicetexte')}</span>
                    </li>
                    <li className='eighth-service'>
                        <img src={EighthServiceImg} alt={t('eighthservicealt')} className='eighth-service-icon'/>
                        <span>{t('eighthservicetexte')}</span>
                    </li>    
                </ul>
            </section>


            {/*guide */}

            <section 
                className={`guide-section ${i18n.language === 'ar' ? 'right-to-left' : ''}`} 
            >
                {/*Guide*/}
                <div className='guide'>
                    <div className='section-text guide-text'>
                        <div className='section-title'>{t('guidetitre')}</div>
                        <div className='guide-subtitle'>{t('guidesoustitre')}</div>
                    </div>

                    <div className='guide-image'>
                        {i18n.language === 'ar' ?
                            <img src={guideImg2ar} alt={t('guidealt')}/>
                            :
                            <img src={guideImg2} alt={t('guidealt')}/>
                        }
                    </div>

                    <div className="custom-shape-divider-bottom-1709349558">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
                        </svg>
                    </div>
                    <ul className='guide-instuctions'>         
                        <li>
                            <img src={Cercle1} alt={t('instruction1alt')}/>
                            <span>{t('instruction1texte')}</span>
                        </li>
                        <li>
                            <img src={Cercle2} alt={t('instruction2alt')}/>
                            <span>{t('instruction2texte')}</span>
                        </li>
                        <li>
                            <img src={Cercle3} alt={t('instruction3alt')}/>
                            <span>{t('instruction3texte')}</span>
                        </li>
                        <li>
                            <img src={Cercle4} alt={t('instruction4alt')}/>
                            <span>{t('instruction4texte')}</span>              
                        </li>
                        <li>
                            <img src={Cercle5} alt={t('instruction5alt')}/>
                            <span>{t('instruction5texte')}</span>
                        </li>
                    </ul>

                    <div className="custom-shape-divider-top-1709349396">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
                        </svg>
                    </div>
                </div>




                {/*subscription-steps*/}

                {/*CCP*/}
                <ul className={`subscription-steps ${i18n.language === 'ar' ? 'right-to-left' : ''}`}>
                    <li className='subscription-step'>
                        <div className='subscription-image'>
                            <img src={CCPImg} data-aos={i18n.language === 'ar' ? "fade-left" : "fade-right"} data-aos-duration="500"  alt={t('subscriptionstep1alt')}/>
                        </div>


                        <div className='subscription-text '>
                            <div className='colored-div-img'>                  
                                <img src={ccpLogo} className='scale-effect' alt={t('subscriptionstep1alt')}/>
                            </div>
                            <span className='subscription-title'>{t('subscriptionstep1titre')}</span>
                            <ul className='paiement-steps'>
                                <li>
                                    <img src={pinkCircle1} alt="number 1"/>
                                    <span>{t('subscription1etape1')}</span>
                                </li>
                                <li>
                                    <img src={pinkCircle2} alt="number 2"/>
                                    <span>{t('subscription1etape2')}</span>
                                </li>
                                <li>
                                    <img src={pinkCircle3} alt="number 3"/>
                                    <span>{t('subscription1etape3')}</span>
                                </li>
                            </ul>
                            <NavLink className='subscription-link btn-decoration' to="/app"><i>{i18n.language === 'ar' ? <FaLongArrowAltLeft className='arrow-right-logo'/> : <FaLongArrowAltRight className='arrow-right-logo'/>}</i> <span>{t('subscriptionsteplink')}</span></NavLink>
                        </div>
                    </li>

                    <div className="custom-shape-divider-bottom-1709259607">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                        </svg>
                    </div>
                </ul>

                {/*BARIDI MOB && ESPECE*/}
                <ul className={`subscription-steps-gray subscription-steps ${i18n.language === 'ar' ? 'right-to-left' : ''}`}>
                    {/*BARIDI MOB*/}
                    <li className='subscription-step subscription-step-two'>
                        <div className='subscription-text second-subscription-text'>
                            <div className='colored-div-img'>                  
                                <img src={baridimobLogo} className='scale-effect' alt={t('subscriptionstep2alt')}/>
                            </div>
                            <span className='subscription-title'>{t('subscriptionstep2titre')}</span>
                            <ul className='paiement-steps'>
                                <li>
                                    <img src={pinkCircle1} alt="number 1"/>
                                    {t('subscription2etape1')}
                                </li>
                                <li>
                                    <img src={pinkCircle2} alt="number 2"/>
                                    {t('subscription2etape2')}
                                </li>
                                <li>
                                    <img src={pinkCircle3} alt="number 3"/>
                                    {t('subscription2etape3')}
                                </li>
                            </ul>                          
                            <NavLink to="/app" className='subscription-link btn-decoration'><i>{i18n.language === 'ar' ? <FaLongArrowAltLeft className='arrow-right-logo'/> : <FaLongArrowAltRight className='arrow-right-logo'/>}</i> <span>{t('subscriptionsteplink')}</span></NavLink>
                        </div>


                        <div className='subscription-image'>
                            <img src={BARIDIMOBImg} data-aos={i18n.language === 'ar' ? "fade-right" : "fade-left"} data-aos-duration="500" alt={t('subscriptionstep2alt')}/>
                        </div>
                    </li>


                    {/*ESPECE*/}
                    <li className='subscription-step'>
                        <div className='subscription-image subscription-image-3'>
                            <img src={PaiementCash}  data-aos={i18n.language === 'ar' ? "fade-left" : "fade-right"} data-aos-duration="500" alt={t('subscriptionstep3alt')}/>
                        </div>

                        
                        <div className='subscription-text third-subscription-text'>
                            <div className='colored-div-img'>                  
                                <img src={especeLogo} className='scale-effect' alt={t('subscriptionstep2alt')}/>
                            </div>
                            <span className='subscription-title'>{t('subscriptionstep3titre')}</span>
                            <ul className='paiement-steps'>
                                <li>
                                    <img src={pinkCircle1} alt="number 1"/>
                                    {t('subscription3etape1')}
                                </li>
                                <li>
                                    <img src={pinkCircle2} alt="number 2"/>
                                    {t('subscription3etape2')}
                                </li>
                                <li>
                                    <img src={pinkCircle3} alt="number 3"/>
                                    {t('subscription3etape3')}
                                </li>
                                <li>
                                    <img src={pinkCircle4} alt="number 3"/>
                                    {t('subscription3etape4')}
                                </li>
                            </ul>
                            
                            <NavLink to="/app" className='subscription-link btn-decoration'><i>{i18n.language === 'ar' ? <FaLongArrowAltLeft className='arrow-right-logo'/> : <FaLongArrowAltRight className='arrow-right-logo'/>}</i> <span>{t('subscriptionsteplink')}</span></NavLink>
                        </div>
                    </li>
                    
                </ul>
                <div className="custom-shape-divider-top-1709271348">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                        </svg>
                </div>
            </section>


            {/*services gratuit */}
            <section 
                className={`free-section ${i18n.language === 'ar' ? 'right-to-left' : ''}`} 
            >

                <div className='section-text'>
                    <div className='section-title'>{t('servicesgratuitstitre')}</div>
                    <div className='section-subtitle'>{t('servicesgratuitssoustitre')}</div>
                </div>

                <div className='free-services'>
                    <div className='free-service'>
                        <img src={i18n.language === 'ar' ? freeService1arabe : freeService1 } data-aos="zoom-in" data-aos-duration="1000" alt="illustration service1"/>
                    </div>
                    <div className='free-service'>
                        <img src={i18n.language === 'ar' ? freeService2arabe : freeService2 } data-aos="zoom-in" data-aos-duration="1000" alt="illustration service2" />
                    </div>
                </div>
                        
            </section>


            {/*Available subjects */}
            <section className={`available-section ${i18n.language === 'ar' ? 'right-to-left' : ''}`}>            
                <div className='section-text'>
                    <div className='section-title'>{t('availablesubjecttitre')}</div>
                    <div className='section-subtitle'>{t('availablesubjectsoustitre')}</div>
                </div>
                <div className="available-section-container">
                    <span className='scale-effect-little'><VscCheck className='available-check'/> {t('histoire')}</span>
                    <span className='scale-effect-little'><VscCheck className='available-check'/> {t('geographie')}</span>
                    <span className='scale-effect-little'><VscCheck className='available-check'/> {t('educationislamique')}</span>
                    <span className='scale-effect-little'><VscCheck className='available-check'/> {t('francais')}</span>
                    <span className='scale-effect-little'><VscCheck className='available-check'/> {t('anglais')}</span>
                    <span className='scale-effect-little'><VscCheck className='available-check'/> {t('espagnol')}</span>
                    <span className='scale-effect-little'><VscCheck className='available-check'/> {t('geniecivil')}</span>
                    <span className='scale-effect-little'><VscCheck className='available-check'/> {t('sciencesnaturelles')}</span>
                </div>  
            </section>



            {/*Aperçu */}
            <section 
                className={`apercu-section ${i18n.language === 'ar' ? 'right-to-left' : ''}`} 
            >
                <div className='section-text'>
                    <div className='section-title'>{t('apercutitre')}</div>
                    <div className='section-subtitle'>{t('apercusoustitre')}</div>
                </div>
                <Carousel/>           
            </section>






               {/*FAQ */}
  
            <section className={`faq-section ${i18n.language === 'ar' ? 'right-to-left' : ''} ` } >
                <div className='section-text'>
                    <div className='section-title'>{t('faqtitre')}</div>
                    <div className='section-subtitle'>{t('faqsoustitre')}</div>
                </div>

                <div className="faq-accordion">
                {faqData.map((item, index) => (
                    <div key={index} className="faq-item">
                    <div className="faq-question" onClick={() => toggleAccordion(index)}>
                        <p>{item.question}</p>
                        {/* Utilisation des icônes de React Icons */}
                        {activeIndex === index ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    </div>
                    {activeIndex === index && (
                        <div className="faq-answer">
                        <p>{item.answer}</p>
                        </div>
                    )}
                    </div>
                ))}
                </div>
            </section>


            {/*contact */}
            <section 
                className="landing-contact-section"
            >
                <div className={`landing-contact-content ${i18n.language === 'ar' ? 'right-to-left' : ''}`}> 
                    <div className='contact-text'>
                        <div className="section-title">{t('rejoigneznoustitre')}</div>
                        <div className="section-subtitle">{t('rejoigneznoustxt')}</div>
                    </div>

                    <ul className="social-icons">
                        <li>
                            <a href="https://www.facebook.com/revizo.co/" target='_blank' rel="noreferrer">
                                <RiFacebookCircleLine className='social-icon'/>             
                            </a>                        
                        </li>
                        <li>
                            <a href="https://www.instagram.com/revizo.co/" target='_blank' rel="noreferrer">
                                <FaInstagram className='social-icon-small'/>
                            </a>
                        </li> 
                        <li>
                            <a href="https://www.tiktok.com/@revizo.co" target='_blank' rel="noreferrer">
                                <FaTiktok className='social-icon-small'/>
                            </a>
                        </li>
                    </ul>

                </div>
            </section>

            <ToastContainer className="custom-toast-container" />


            {/*Footer*/}
            <footer>
                <div className='footer-terms-conditions'>
                    <TermsConditions classnamesize="terms-normal-size-w" text2={t('termesetconditionstitre')}/>
                </div>

                <span>©  2023 - Revizo.</span>
            </footer>

            {/*Float select language icon */}
            <SelectLanguageFloat changeLanguage={changeLanguage} />

    </div>
  )
}

export default LandingPage

